import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

import AddToCart from './AddToCart.js'
import ProductImage from './ProductImage.js'

import { GlobalStateContext } from '../Site.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { showToast } from '../tools/Toast.js'

function ProductItem(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [quantityInCart, setQuantityInCart] = useState(0);
	const [openProductDetail, setOpenProductDetail] = useState(false);
	const { t } = useTranslation();
	
	const updateQuantity = (quantity) => {
		if(quantity > props.product.quantite) {
			showToast(t("toast.product_out_of_stock"));
		}
		else {
			setQuantityInCart(quantity);
			globalState.cart.updateProduct(
				props.product,
				quantity,
				globalState,
				setGlobalState,
				() => {},
				(product) => {
					if(quantity > 0) {
						showToast(t("toast.add_product_failed"));
					}
				}
			);
		}
	}

	useEffect(() => {
		setQuantityInCart(globalState.cart.getQuantityProduct(props.product));
	}, [globalState.cart]);
	
	return(
		<div className="product-item col">
		  <div className="card h-100 rounded-4 overflow-hidden osahan-card-list cursor-pointer" onClick={(e) => { props.selectProduct(props.product); }}>
			 <div className="product-item-content d-flex flex-lg-column h-100">
				<div className="product-item-image">
					<ProductImage className="card-img-top" idProduct={props.product.idProduit} idImage={(props.product.images?.length > 0 ? props.product.images[0].idImage : null)}/>
				</div>
				<div className="product-item-details d-flex flex-column justify-content-between flex-grow-1">
					 <div className="card-body pt-0 pb-0">
						<h6 className="card-title fw-bold text-truncate">{props.product.nomCourt ? props.product.nomCourt : props.product.nom} </h6>
						<div className="text-muted small mb-2 l-2" dangerouslySetInnerHTML={{ __html: props.product.descriptionCourte}}></div>
					 </div>
					 <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between pt-0 pb-3">
						<h6 className="fw-bold m-0">{currencyFormat(props.product.prixTTC)}</h6>
						{props.product.quantite > 0 &&
							<i className="bi bi-plus-circle d-flex m-0 h4 text-black"></i>
						}
						{props.product.quantite <= 0 &&
							<div className="product-item-out-stock">
								<i className="bi bi-slash-circle m-1"> </i>
								<span>{t("product.out_of_stock")}</span>
							</div>
						}
					 </div>
				</div>
			 </div>
			 <div className={"product-item-overlay " + (props.product.quantite > 0 ? "display-none" : "")}></div>
		  </div>
		</div>
	);
}
export default ProductItem;