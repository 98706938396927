import { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { parsePhoneNumber } from 'react-phone-number-input/max'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import _ from "lodash";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { checkName, formatPhone, replaceAll } from '../tools/StringTools.js'
import { showToast } from '../tools/Toast.js'

import CustomBottomSheet from './CustomBottomSheet.js'
import LoadingButton from './LoadingButton.js'
import Map from './Map.js'

function AddAddress(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	
	const getEmptyAddress = () => {
		let phoneNumber = null;
		if(globalState?.loginPhone != null) {
			phoneNumber = parsePhoneNumber(globalState.loginPhone, 'FR');
		}
		else if((globalState?.customer?.adresses?.length > 0) && (globalState.customer.adresses[0].telephone != null)) {
			phoneNumber = parsePhoneNumber(globalState.customer.adresses[0].telephone, 'FR');
		}
		return {
			firstname: (globalState?.customer?.prenom ?? ""),
			lastname: (globalState?.customer?.nom ?? ""),
			phone: (phoneNumber?.number ?? ""),
			deliveryNotes: "",
			label: "",
			lat: null,
			lng: null,
			addressComponents: null,
		};
	};
	const [address, setAddress] = useState(getEmptyAddress());
	const [addressInputFocused, setAddressInputFocused] = useState(false);
	const [resetCounter, setResetCounter] = useState(0);
	const [buttonLoading, setButtonLoading] = useState(false);
	
	const closeButton = useRef();
	const firstnameInput = useRef();
	const lastnameInput = useRef();
	const addressInput = useRef();
	const addressValueInput = useRef();
	const deliveryNotesInput = useRef();
	const phoneInput = useRef();
	const map = useRef();
	
	const { t } = useTranslation();
		
	const coordsCenter = {
		lat: 45.901103,
		lng: 6.127644,
		zoom: 12
	};
	
	const [coordsOnMap, setCoordsOnMap] = useState(null);
		
	const getAddressObject = (address_components) => {
		if(address_components == null)
			return null;
		var ShouldBeComponent = {
			street_number: ["street_number"],
			postal_code: ["postal_code"],
			street: ["street_address", "route"],
			city: [
			  "locality",
			  "sublocality",
			  "sublocality_level_1",
			  "sublocality_level_2",
			  "sublocality_level_3",
			  "sublocality_level_4"
			]
		};

		var address = {
			street_number: "",
			postal_code: "",
			street: "",
			city: "",
		};
		address_components.forEach(component => {
			for (var shouldBe in ShouldBeComponent) {
				if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
					if (shouldBe === "street") {
						address[shouldBe] = component.short_name;
					} 
					else {
						address[shouldBe] = component.long_name;
					}
				}
			}
		});
		return address;
    }
	
	const checkFields = () => {
		let check = true;
		if((address.firstname == null) || (address.firstname == "") || !checkName(address.firstname)) {
			check = false;
			firstnameInput.current.className = firstnameInput.current.className + " border-error";
		}
		if((address.lastname == null) || (address.lastname == "") || !checkName(address.lastname)) {
			check = false;
			lastnameInput.current.className = lastnameInput.current.className + " border-error";
		}
		if((address.addressComponents == null) || (address.addressComponents.street == "") || (address.addressComponents.postal_code == "") || (address.addressComponents.city == "") || (address.lat == null) || (address.lng == null)) {
			check = false;
			addressInput.current.className = addressInput.current.className + " border-error";
		}
		let phoneNumber = parsePhoneNumber(address.phone != null ? address.phone : "");
		if((phoneNumber == null) || (!phoneNumber.isValid())) {
			check = false;
			phoneInput.current.className = phoneInput.current.className + " border-error";
		}
		return check;
	}
	
	const save = () => {
		if(checkFields()) {
			setButtonLoading(true);
			let params = {
				"nom": address.lastname.trim(),
				"prenom": address.firstname.trim(),
				"adresse": (address.addressComponents.street_number != "" ? (address.addressComponents.street_number + " ") : "") + address.addressComponents.street,
				"complementAdresse": address.deliveryNotes,
				"cp": address.addressComponents.postal_code,
				"ville": address.addressComponents.city.toUpperCase(),
				"telephone": formatPhone(address.phone),
				"id_client": globalState.customer.idClient,
				"latitude": address.lat,
				"longitude": address.lng,
				"desactive_doublons": 1
			};
			if(props.address != null) {
				params = {
					...params,
					"id_adresse": props.address.idAdresse
				};
				Api(
					"modifyAdresse",
					params,
					(idAddress) => {
						getCustomer(idAddress);
					},
					(idError) => {
						setButtonLoading(false);
						showToast(t("toast.oopsError"));
					},
					globalState.token,
					globalState.setToken
				);
			}
			else {
				Api(
					"addAdresse",
					params,
					(idAddress) => {
						getCustomer(idAddress);
					},
					(idError) => {
						setButtonLoading(false);
						showToast(t("toast.oopsError"));
					},
					globalState.token,
					globalState.setToken
				);
			}
			
		}
		else {
			showToast(t("toast.errorInput"));
		}
	};
	
	const getCustomer = (idAddressToSelect) => {
		Api(
			"getClientToken", 
			{},
			(data) => {
				let cart =  _.cloneDeep(globalState.cart);
				cart.idAddress = idAddressToSelect;
				setGlobalState(prevState => ({
					...prevState,
					customer: data,
					cart: cart,
				}));
				props.setIsOpen(false);
				setButtonLoading(false);
			},
			(idError) => {
				showToast(t("toast.oopsError"));
				setButtonLoading(false);
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	useEffect(() => {
		let phoneNumber = null;
		
		if(props.address?.telephone != null) {
			phoneNumber = parsePhoneNumber(props.address.telephone, 'FR');
		}
		else if(globalState?.loginPhone != null) {
			phoneNumber = parsePhoneNumber(globalState.loginPhone, 'FR');
		}
		else if((globalState?.customer?.adresses?.length > 0) && (globalState.customer.adresses[0].telephone != null)) {
			phoneNumber = parsePhoneNumber(globalState.customer.adresses[0].telephone, 'FR');
		}
		setAddress(prevState => ({
			...prevState,
			firstname: (props.address?.prenom != null ? props.address.prenom : (globalState?.customer?.prenom ?? "")),
			lastname: (props.address?.nom != null ? props.address.nom : (globalState?.customer?.nom ?? "")),
			phone: (phoneNumber != null ? phoneNumber.number : ""),
			deliveryNotes: (props.address?.complementAdresse != null ? props.address.complementAdresse : ""),
			label: ((props.address?.adresse != null && props.address?.ville != null) ? (props.address.adresse + ", " + props.address.ville) : ""),
			lat: props.address?.latitude,
			lng: props.address?.longitude,
			addressComponents: (props.address != null ? {
				street_number: "",
				postal_code: props.address.cp,
				street: props.address.adresse,
				city: props.address.ville,
			} : null)
		}));
		setResetCounter(resetCounter + 1);
	}, [props.address, globalState?.customer?.adresses]);
	
	useEffect(() => {
		if((address.lat != null) && (address.lng != null)) {
			setCoordsOnMap({
				lat: parseFloat(address.lat),
				lng: parseFloat(address.lng),
				zoom: 18
			});
		}
		else
			setCoordsOnMap({...coordsCenter});
	}, [address?.lat, address?.lng]);
	
	return(
		<CustomBottomSheet id="add-address" type="modal" isOpen={props.isOpen} setIsOpen={props.setIsOpen} noPadding={true} >
			 <div className="row g-0" key={resetCounter}>
				<div className="col-lg-6">
				   <div className="ratio h-100">
						<Map coordsOnMap={coordsOnMap} />
				   </div>
				</div>
				<div className="col-lg-6 p-4">
				   <div className="mb-3 pe-5">
					  <h5 className="fw-bold mb-1">{(props.address != null ? t("addAddress.titleEdit") : t("addAddress.titleAdd"))}</h5>
					  <p className="text-muted small m-0">{t("addAddress.subtitle")}</p>
				   </div>
				   <form>
					  <div className="row g-3 mb-3">
						 <div className="col-6">
							<div className="form-floating h-100">
							   <input 
									type="text"
									className="form-control"
									id="firstname"
									name="firstname"
									placeholder="firstname"
									value={address.firstname}
									onChange={(e) => {
										firstnameInput.current.className = replaceAll(firstnameInput.current.className, " border-error", "");
										setAddress(prevState => ({
											...prevState,
											firstname: e.target.value
										}));
									}}
									ref={firstnameInput}
								/>
								<label htmlFor="firstname">{t("form.firstname")}</label>
							</div>
						 </div>
						 <div className="col-6">
							<div className="form-floating h-100">
							   <input 
									type="text"
									className="form-control"
									id="lastname"
									name="lastname"
									placeholder="lastname"
									value={address.lastname}
									onChange={(e) => {
										lastnameInput.current.className = replaceAll(lastnameInput.current.className, " border-error", "");
										setAddress(prevState => ({
											...prevState,
											lastname: e.target.value
										}));
									}}
									ref={lastnameInput}
								/>
							   <label htmlFor="lastname">{t("form.lastname")}</label>
							</div>
						 </div>
					  </div>
					  <div className="form-floating mb-3 form-control" ref={addressInput}>
						<GooglePlacesAutocomplete
							id="address"
							name="address"
							apiKey="AIzaSyB2hg2WFRtVV16thSjkZKcJZq-rb6vFM9s"
							apiOptions={{ language: 'fr', region: 'fr' }}
							autocompletionRequest={{
								location: {lat: 45.901103, lng: 6.127644},
								radius: 20000,
								types: ["address"],
							}}
							selectProps={{
								placeholder: "",
								noOptionsMessage: () => { return "Veuillez saisir une adresse" },
								loadingMessage: () => { return "Chargement..." },
								className: 'address-input',
								styles: {
									control: (provided) => ({
										...provided,
										border: 'none',
										boxShadow: 'none',
										paddingTop: '1.625rem'
									}),
									indicatorsContainer: (provided) => ({
										...provided,
										display: 'none'
									}),
									input: (provided) => ({
										...provided,
										outline: 'none',
										padding: 0,
										display: 'flex',
									}),
									valueContainer: (provided) => ({
										...provided,
										padding: 0,
									}),
								},
								defaultInputValue: address.label,
								onChange: (selected) => {
									addressInput.current.className = replaceAll(addressInput.current.className, " border-error", "");
									geocodeByPlaceId(selected.value.place_id)
										.then(results => {
											if(results.length > 0) {
												setAddress(prevState => ({
													...prevState,
													lat: results[0].geometry.location.lat(),
													lng: results[0].geometry.location.lng(),
													addressComponents: getAddressObject(results[0].address_components),
													label: selected.label,
												}));
											}
										})
										.catch(error => console.error(error));
								},
								onFocus: (event) => {
									setAddressInputFocused(true);
								},
								onBlur: (event) => {
									setAddressInputFocused(false);
								}
							}}
							ref={addressValueInput}
						/>
						{(addressInputFocused || address.addressComponents != null || address.label != "") &&
							<input type="hidden" className="form-control"/>
						}
						<label htmlFor="address">{t("addAddress.address")}</label>
					  </div>
					  <div className="form-floating mb-3 textarea-container form-control" ref={deliveryNotesInput}>
						<textarea 
							type="text"
							className="form-control"
							rows="2"
							id="deliveryNotes"
							name="deliveryNotes"
							placeholder="deliveryNotes"
							value={address.deliveryNotes}
								onChange={(e) => 
								setAddress(prevState => ({
									...prevState,
									deliveryNotes: e.target.value
								}))
							}
						/>
						<label htmlFor="deliveryNotes">{t("addAddress.deliveryNotes")}</label>
					  </div>
					  <div className="form-floating mb-3" ref={phoneInput}>
						 <PhoneInput
							className="form-control"
							id="phone"
							name="phone"
							value={address.phone}
							onChange={phone => {
								phoneInput.current.className = replaceAll(phoneInput.current.className, " border-error", "");
								setAddress(prevState => ({
									...prevState,
									phone: phone
								}));
							}}
							onKeyDown={(e) => {
								if(e.key == "Enter") {
									e.preventDefault();
									save();
								}
							}}
							initialValueFormat="national"
							defaultCountry="FR"
							countrySelectProps={{className: "disabled"}}
							addInternationalOption={false}
						 />
						<label htmlFor="phone">{t("form.phone")}</label>
					  </div>
					  <LoadingButton onClick={(e) => { save(); }} title={t("button.save")} loading={buttonLoading} />
				   </form>
				</div>
			 </div>
		</CustomBottomSheet>
	);
}
export default AddAddress;