import axios from "axios";
import Cookies from 'universal-cookie';

const Api = (action, params, onSuccess, onFailure, token, setToken, retryAfterLogin = true) => {
	
	const utf8 = require('utf8');
	
	const toUtf8 = (object) => {
		if(object == null) {
			return null;
		}
		else if(Array.isArray(object)) {
			object.forEach(function (value, i) {
				object[i] = toUtf8(object[i]);
			});
			return object;
		}
		else if(typeof object === 'object') {
			for (const [key, value] of Object.entries(object)) {
				object[key] = toUtf8(value);
			}
			return object;
		}
		else if(typeof object === 'string') {
			return utf8.decode(object);
		}
		else {
			return object;
		}
	}
	
	params["action"] = action;
	params["token"] = token;
	axios
		.get(process.env.REACT_APP_API_URL, { params: params })
		.then((response) => {
			if(process.env.REACT_APP_ENVIRONMENT !== 'production')
				console.log(response);
			if(response.data.error == 0) {
				onSuccess(toUtf8(response.data.content));
			}
			else {
				let idError = response.data.error;
				if((action != "login") && retryAfterLogin) {
					if(idError == 6) {
						const cookies = new Cookies(null, { path: '/' });
						let tokenCookies = cookies.get("token");
						if((tokenCookies != null) && (tokenCookies != token)) {
							setToken(tokenCookies);
							Api(action, params, onSuccess, onFailure, tokenCookies, setToken, retryAfterLogin);
						}
						else {
							window.location.reload();
						}
					}
					else if(idError == 2) {
						window.location.reload();
					}
					else {
						onFailure(idError);
					}
				}
				else {
					onFailure(idError);
				}
			}
		})
		.catch((error) => {
			if(process.env.REACT_APP_ENVIRONMENT !== 'production')
				console.log(error);
			onFailure(0);
		})
}

export { Api }