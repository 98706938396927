import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { showTidio } from '../tools/Tidio.js'

function Error404() {
	
	const { t } = useTranslation();
	
	return (
		<>
			<Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
			<div className="vh-100 my-auto overflow-auto">
				<div className="container d-flex flex-column h-100">
				   <div className="row justify-content-center align-items-center h-100">
					  <div className="mx-auto col-lg-10">
						 <div className="row justify-content-center align-items-center">
							<div className="col-md-6">
							   <div className=" mb-6 mb-lg-0 text-center py-4">
								  <h1 className="mt-4">{t("error404.title")}</h1>
								  <p className="mb-5">{t("error404.subtitle")}</p>
								  <Link to="/" className="py-2 px-4 rounded-pill btn btn-danger">{t("global.home")}</Link>
								  <Link onClick={showTidio} className="py-2 px-4 rounded-pill btn btn-dark ms-2">{t("contact.title")}</Link>
							   </div>
							</div>
							<div className="col-md-6 px-4 px-lg-0">
							   <div>
								  <img src={require("../img/water.png")} alt="" className="img-fluid"/>
							   </div>
							</div>
						 </div>
					  </div>
				   </div>
				</div>
			</div>
		</>
	);
}
export default Error404;