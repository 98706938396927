import ProductItem from './ProductItem.js'
import { removeAccents, replaceAll } from '../tools/StringTools.js'

function ProductList(props) {

	return(
		<div className="ProductList container p-3" id={"category-" + props.idCategory}>
			<div className="d-flex align-items-center justify-content-between mb-3">
			   <h6 className="m-0">{props.categoryName}</h6>
			</div>
			<div className="row row-cols-xl-5 row-cols-lg-5 row-cols-md-1 row-cols-1 g-3 row-products">
			{props.products.map(product => {
				if(product.active == 1)
					return <ProductItem product={product} selectProduct={props.selectProduct} key={product.idProduit} />
			})}
			</div>
		</div>
	);
}
export default ProductList; 