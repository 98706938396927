import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import { GlobalStateContext } from '../Site.js'
import { currencyFormat } from '../tools/NumberTools.js'

import CartItem from '../components/CartItem.js'
import LoginAndVerify from '../components/LoginAndVerify.js'
import ProductDetail from '../components/ProductDetail.js'

function Cart(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [cart, setCart] = useState(globalState?.cart);
	const [openProductDetail, setOpenProductDetail] = useState(false);
	const [productSelected, setProductSelected] = useState(null);
	const [openLoginAndVerify, setOpenLoginAndVerify] = useState(false);
	const [resetLoginAndVerify, setResetLoginAndVerify] = useState(0);
	const { t } = useTranslation();
	
	const selectProduct = (product) => {
		setProductSelected(product);
		setOpenProductDetail(true);
	}
	
	useEffect(() => {
		setCart(globalState?.cart);
	}, [globalState?.cart]);
	
	useEffect(() => {
		props.setReturnPage("/");
		props.setPageTitle(t("cart.title"));
	}, []);

	return(
		<div className="row osahan-my-account-page border-secondary-subtle overflow-auto g-0">
			<div className="px-4 py-3">
			   <h5 className="offcanvas-title fw-bold">{t("cart.my_cart")}</h5>
			   {cart?.getNbProducts() > 0 &&
					<p className="text-black-50 small m-0">{cart?.getNbProducts()} {cart?.getNbProducts() > 1 ? t("global.items") : t("global.item")}</p>
			   }
			</div>
			{cart?.products.length > 0 &&
				<div className="col-lg-8 border-end">
					<div className="cart-body p-0 overflow-auto">
						{cart?.products.map(p => {
							return <CartItem product={p.product} quantity={p.quantity} selectProduct={selectProduct} userInteraction={true} key={p.product.idProduit} />
						})}
					</div>
				</div>
			}
			{cart?.products.length > 0 &&
				<div className="col-lg-4 bg-light">
					<div className="p-4 bg-light cart-total">
					  <div className="d-flex align-items-center justify-content-between mb-1">
						 <p className="text-muted m-0">{t("cart.total_products")}</p>
						 <p className="m-0">{currencyFormat(cart?.getTotalProducts())}</p>
					  </div>
					  {cart?.voucher != null &&
						  <div className="d-flex align-items-center justify-content-between mb-3">
							<div className="d-flex flex-column w-80">
							 <p className="text-muted m-0">{t("cart.total_discount")}</p>
							 <p className="text-muted text-truncate small m-0 ms-3">{cart.voucher.description}</p>
							</div> 
							 <p className="m-0">- {currencyFormat(cart?.getTotalDiscount())}</p>
						  </div>
					  }
					  <div className="d-flex align-items-center justify-content-between">
						 <h6 className="fw-bold m-0">{t("cart.total")}</h6>
						 <p className="fw-bold text-info m-0">{currencyFormat(cart?.getTotalProducts() - cart?.getTotalDiscount())}</p>
					  </div>
				   </div>
					<div className="cart-footer p-4">
						{globalState?.customer == null &&
							<Link className="btn btn-danger fw-bold d-flex align-items-center justify-content-between py-3 px-4 w-100 rounded-4 shadow" onClick={(e) => {setOpenLoginAndVerify(true); setResetLoginAndVerify(resetLoginAndVerify + 1);}}>{cart?.getNbProducts()} {cart?.getNbProducts() > 1 ? t("global.items") : t("global.item")} &#183; {currencyFormat(cart?.getTotal())}
								<span className="text-uppercase">{t("cart.proceed")}<i className="icofont-double-right ms-1"></i></span>
							</Link>
						}
						{globalState?.customer != null &&
							<Link to='/delivery' className="btn btn-danger fw-bold d-flex align-items-center justify-content-between py-3 px-4 w-100 rounded-4 shadow">{cart?.getNbProducts()} {cart?.getNbProducts() > 1 ? t("global.items") : t("global.item")} &#183; {currencyFormat(cart?.getTotalProducts() - cart?.getTotalDiscount())}
								<span className="text-uppercase">{t("cart.proceed")}<i className="icofont-double-right ms-1"></i></span>
							</Link>
						}
					   
					</div>
				</div>
			}
			{cart?.products.length == 0 &&
				<div className="cart-empty d-flex align-items-center justify-content-center">
					<i className="bi bi-basket3"></i>
					<h6>{t("cart.your_cart_is_empty")}</h6>
				</div>
			}
			<LoginAndVerify isOpen={openLoginAndVerify} setIsOpen={setOpenLoginAndVerify} resetView={resetLoginAndVerify}/>
			<ProductDetail isOpen={openProductDetail} setIsOpen={setOpenProductDetail} product={productSelected} />
		</div>
	);
}
export default Cart;