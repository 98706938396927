import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

import QuantitySelector from './QuantitySelector.js'
import ProductImage from './ProductImage.js'

import { GlobalStateContext } from '../Site.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { showToast } from '../tools/Toast.js'

function CartItem(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [quantityInCart, setQuantityInCart] = useState(props.quantity);
	const { t } = useTranslation();
	
	const updateQuantity = (quantity) => {
		if(quantity > props.product.quantite) {
			showToast(t("toast.product_out_of_stock"));
		}
		else {
			setQuantityInCart(quantity);
			globalState.cart.updateProduct(
				props.product,
				quantity,
				globalState,
				setGlobalState,
				() => {},
				(product) => {
					if(quantity > 0) {
						showToast(t("toast.add_product_failed"));
					}
				}
			);
		}
	}
	
	const selectProduct = () => {
		props.selectProduct(props.product);
	}

	return(
		<div className="px-4 py-3 border-bottom cart-item">
			<div className="row">
			 <div className="col-3">
				<Link  {... (props.userInteraction ? {onClick:selectProduct} : {className:"cursor-default"})}>
					<ProductImage className="img-fluid border rounded-4" idProduct={props.product.idProduit} idImage={(props.product.images?.length > 0 ? props.product.images[0].idImage : null)}/>
				</Link>
			 </div>
			 <div className="col-9">
				<div {... (props.userInteraction ? {onClick:selectProduct, className: "cursor-pointer"} : {})}>
					<h6 className="fw-bold mb-1">{props.product.nom}</h6>
					<div className="text-muted l-2" dangerouslySetInnerHTML={{ __html: props.product.descriptionCourte}}></div>
				</div>
				<div className="d-flex align-items-center justify-content-between">
				   <h6 className="fw-bold m-0">{currencyFormat(props.product.prixTTC)}</h6>
				   {props.userInteraction &&
						<QuantitySelector quantity={quantityInCart} setQuantity={updateQuantity} />
				   }
				   {!props.userInteraction &&
						<h6 className="m-0">x{quantityInCart}</h6>
				   }
				</div>
			 </div>
			</div>
		</div>
	);
}
export default CartItem;