import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { GlobalStateContext } from '../Site.js'

import Order from './Order.js'

function Footer() {
	
	const {globalState, setGlobalState} = useContext(GlobalStateContext);
	const [cartHighlight, setCartHighlight] = useState(false);
	const [openOrder, setOpenOrder] = useState(false);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		if(globalState?.cart.products.length > 0) {
			setCartHighlight(true);
			setTimeout(() => {
				setCartHighlight(false);
			}, 3000);
		}
	}, [globalState?.cart]);

	return(
		<div className="footer bg-white shadow border-top mt-auto">
			<div className="d-flex align-items-center justify-content-between py-1">
				<Link to='/' className="link-dark text-center col py-2 p-1">
				   <i className="bi bi-house h3 m-0"></i>
				   <p className="small m-0 pt-1">{t("global.home")}</p>
				</Link>
				<Link to='/cart' className={"text-muted text-center col py-2 p-1 cart-footer-button" + (cartHighlight ? " cart-highlight" : "")} >
				   <i className="bi bi-basket h3 m-0"></i>
				   <p className="small m-0 pt-1">{t("global.cart")}</p>
				</Link>
				<Link to="/concept" className="text-muted text-center col py-2 p-1">
				   <i className="bi bi-lightning-charge h3 m-0"></i>
				   <p className="small m-0 pt-1">{t("concept.title")}</p>
				</Link>
				<Link className="text-muted text-center col py-2 p-1" onClick={(e) => setOpenOrder(true)}>
				   <i className="bi bi-truck h3 m-0"></i>
				   <p className="small m-0 pt-0" style={{fontSize: "0.7em", lineHeight: "1.2em"}}>{t("footer.order", {returnObjects: true})[0]}<br/>{t("footer.order", {returnObjects: true})[1]}</p>
				</Link>
			<Order isOpen={openOrder}setIsOpen={setOpenOrder} />
		</div>
		</div>
	);
}
export default Footer;