import { useState, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import CustomBottomSheet from './CustomBottomSheet.js';


function Order(props) {
	
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	return(
		<CustomBottomSheet id={t("order.url")} title={t("order.title")} isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
			<div className="col-lg-7 mx-auto px-4">
				<div className="w-100 d-flex justify-content-center flex-column gap-3">
					<Link className="py-3 px-2 rounded-4 shadow-sm bg-light border d-flex align-items-center justify-content-centertext-center gap-3 mb-4 text-black" onClick={close}>
						<div className="h-100 d-flex justify-content-center align-items-center border-end pe-2" style={{width:'80px'}}>
							<i className="bi bi-basket fs-1"></i>
						</div>
						<div className="d-flex align-items-between flex-column flex-grow-1">
							<h6 className="text-center text-success">{t("order.online.title")}</h6>
							{t("order.online.content", {returnObjects: true}).map((c, i) => {
								return <p className="mb-0" key={i}>{c}</p>
							})}
							<div className="d-flex gap-3 row-gap-0 justify-content-center mt-2 px-2 flex-wrap">
								<img style={{height: "35px"}} src={require('../img/mastercard.png')} />
								<img style={{height: "35px"}} src={require('../img/visa.png')} />
								<img style={{height: "35px"}} src={require('../img/apple_pay.png')} />
								<img style={{height: "35px"}} src={require('../img/google_pay.png')} />
								<img style={{height: "35px"}} src={require('../img/paypal.png')} />
							</div>
						</div>
					</Link>
					<Link to="https://deliveroo.fr/fr/menu/Annecy/le-vallon-taillefer/sos-soiree-annecy" className="py-3 px-2 rounded-4 shadow-sm bg-light border d-flex align-items-center justify-content-centertext-center gap-3 mb-4 text-black">
						<div className="h-100 d-flex justify-content-center align-items-center border-end pe-2" style={{width:'80px'}}>
							<img src={require('../img/deliveroo.png')} width="80%" />
						</div>
						<div className="d-flex align-items-between flex-column flex-grow-1">
							<h6 className="text-center text-success">{t("order.deliveroo.title")}</h6>
							{t("order.deliveroo.content", {returnObjects: true}).map((c, i) => {
								return <p className="mb-0" key={i}>{c}</p>
							})}
						</div>
					</Link>
					<Link to="https://annecy.sossoiree.fr/toUber" className="py-3 px-2 rounded-4 shadow-sm bg-light border d-flex align-items-center justify-content-centertext-center gap-3 mb-4 text-black">
						<div className="h-100 d-flex justify-content-center align-items-center border-end pe-2" style={{width:'80px'}}>
							<img src={require('../img/uber_eats.png')} width="80%" />
						</div>
						<div className="d-flex align-items-between flex-column flex-grow-1">
							<h6 className="text-center text-success">{t("order.uber.title")}</h6>
							{t("order.uber.content", {returnObjects: true}).map((c, i) => {
								return <p className="mb-0" key={i}>{c}</p>
							})}
						</div>
					</Link>
					<Link className="py-3 px-2 rounded-4 shadow-sm bg-light border d-flex align-items-center justify-content-centertext-center gap-3 mb-4 text-black cursor-default">
						<div className="h-100 d-flex justify-content-center align-items-center border-end pe-2 opacity-50" style={{width:'80px'}}>
							<i className="bi bi-telephone fs-1"></i>
						</div>
						<div className="d-flex align-items-between flex-column flex-grow-1 opacity-50">
							<h6 className="text-center text-success">{t("order.telephone.title")}<i className="bi bi-slash-circle ms-4 text-black small"> </i><span className="text-black small">{t("product.out_of_stock")}</span></h6>
							{t("order.telephone.content", {returnObjects: true}).map((c, i) => {
								return <p className="mb-0" key={i}>{c}</p>
							})}
						</div>
					</Link>
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default Order;