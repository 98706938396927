import { useState, useContext, useEffect } from 'react'

import { Link, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { GlobalStateContext } from '../Site.js'

import NavBarIcon from './NavBarIcon.js'
import Menu from './Menu.js'
import Location from './Location.js'

function NavBar(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	
	const { t } = useTranslation();
	const location = useLocation();
	
	useEffect(() => {
		if(globalState?.init && (globalState.userLocation != null) && (globalState.userLocation.isDeliveryTimeObsolete()) && (props.returnPage == null)) {
			globalState.userLocation.getDeliveryTime(globalState, setGlobalState);
		}
	}, [location]);
	
	return(
		<div className="homepage-navbar shadow p-3 bg-primary">
		 <div className="d-flex align-items-center header-logo">
			<Link to="/"> <img src={require('../img/Logo_V2_sans_etiquette_blanc.png')} width="100px" /></Link>
			<div className="d-flex align-items-center gap-2 ms-auto">
				<NavBarIcon type="chat" />
				<NavBarIcon type="cart" />
				<NavBarIcon type="menu" />
			</div>
		</div>
		  
			{ props.returnPage == null && 
				<div className="d-flex align-items-center">
					{(globalState?.userLocation != null) && (globalState.userLocation.getLabel(t) != null) &&
						<Link 
							className="link-dark text-truncate d-flex align-items-center gap-2 cursor-default" 
							/*{... (globalState?.userLocation != null ? 
								{"data-bs-toggle":"offcanvas", 
								"data-bs-target":"#" + (globalState.userLocation.idAddress > 0 ? "myaddress" : "location"),
								"aria-controls": (globalState.userLocation.idAddress > 0 ? "myaddress" : "location")
								} :
								{}
							)}*/
							>
						   <i className="icofont-clock-time fs-2 text-white"></i>
						   <span>
							  <h6 className="fw-bold text-white mb-0">{globalState.userLocation.getDeliveryTimeLabel(t)}</h6>
							  <p className="text-white-50 text-truncate d-inline-block mb-0 align-bottom">{globalState.userLocation.getLabel(t)}</p>
						   </span>   
						</Link>
					}
				</div>
			}
			{ props.returnPage == "" &&
				<div>
					<Link className="d-flex align-items-center gap-3 inner-page-heaer text-white" onClick={(e) => window.history.back()}>
						<i className="bi bi-arrow-left fs-5"></i>
						<h6 className="fw-bold text-white mb-0">{t("navbar.backTo" + props.returnPage)}</h6>
					</Link>
				</div>
			}
			{ props.returnPage != null && props.returnPage != "" &&
				<div>
					<Link to={props.returnPage} className="d-flex align-items-center gap-3 inner-page-heaer text-white">
						<i className="bi bi-arrow-left fs-5"></i>
						<h6 className="fw-bold text-white mb-0">{t("navbar.backTo" + props.returnPage)}</h6>
					</Link>
				</div>
			}
		<Menu categories={props.categories} />
		{ props.returnPage == null && 
			<div>
				<Location />
			</div>
		}
		</div>
	);
}
export default NavBar;