import { useState, useContext, useEffect } from 'react'

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'

function ProductImage(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const imageDefault = require('../img/default.jpeg');
	const [imageData, setImageData] = useState(imageDefault);
	
	const getImage = () => {
		if((props.idProduct != null) && (props.idImage != null)) {
			try {
				if ("caches" in window) {
					caches.open("MyCache").then((cache) => {
						cache.match("/productImage/" + props.idProduct + "/" + props.idImage)
							.then(response => response.json())
							.then((result) => {
								setImageData('data:image/jpeg;base64,' + result);
							})
							.catch((error) => {
								getImageFromApi();
							});
					}).catch(error => {
						getImageFromApi();
					});
				}
				else {
					getImageFromApi();
				}
			}
			catch(error) {
				getImageFromApi();
			}
		}
		else {
			setImageData(imageDefault);
		}
	}
	
	const getImageFromApi = () => {
		Api(
			"getImageProduit",
			{
				"id_produit": props.idProduct,
				"id_image": props.idImage
			},
			(data) => {
				if(data != null) {
					addToCache(data)
					setImageData('data:image/jpeg;base64,' + data);
				}
			},
			(idError) => {},
			globalState.token,
			globalState.setToken
		);
	}
	
	const addToCache = (data) => {
		const dataFormatted = new Response(JSON.stringify(data));
		try {
			if ("caches" in window) {
				caches.open("MyCache").then((cache) => {
					cache.put("/productImage/" + props.idProduct + "/" + props.idImage, dataFormatted)
				}).catch(error => {});
			}
		}
		catch(error) {}
	}
	
	useEffect(() => {
		getImage();
	}, [props.idProduct, props.idImage]);

	return(
		<img src={imageData} alt="" className={props.className} />
	);
}
export default ProductImage;