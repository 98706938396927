function Location() {

	return(
		<div className="offcanvas offcanvas-end my-cart-width" tabIndex="-1" id="location">
			<div className="offcanvas-header px-4 py-3 d-flex align-items-center justify-content-start gap-3 bg-info text-white">
			   <a href="#" className="link-light" data-bs-dismiss="offcanvas" aria-label="Close"><i className="lni lni-arrow-left fs-5 d-flex"></i></a>
			   <h6 className="offcanvas-title" id="locationLabel">Enter your area or apartment name</h6>
			</div>
			<div className="offcanvas-body p-0">
			   <div className="p-4 bg-light">
				  <div className="input-group bg-white rounded-3 border py-1">
					 <a href="#" className="input-group-text bg-transparent border-0 rounded-0 px-3"><i className="icofont-search"></i></a>
					 <input type="text" className="form-control bg-transparent border-0 rounded-0 ps-0" placeholder="Try J P Nagar, Andheri West etc.." aria-label="Try J P Nagar, Andheri West etc.."/>
				  </div>
				  <a href="#" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
					 <div className="d-flex align-items-center gap-2 text-info pt-3">
						<i className="icofont-location-arrow"></i>
						<p className="m-0">Use my Current location</p>
					 </div>
				  </a>
			   </div>
			   <div className="border-bottom p-4">
				  <p className="text-black text-uppercase small">Saved Addresses</p>
				  <div className="d-flex align-items-center justify-content-between">
					 <div className="w-75">
						<a href="#" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
						   <div className="d-flex align-items-center gap-3 osahan-mb-1">
							  <i className="lni lni-home text-muted fs-5"></i>
							  <div>
								 <h6 className="fw-bold mb-1">Home</h6>
								 <p className="text-muted text-truncate mb-0 small">H.No. 2834 Street, 784 Sector, Ludhiana, Punjab</p>
							  </div>
						   </div>
						</a>
					 </div>
				  </div>
			   </div>
			   <div className="p-4">
				  <p className="text-black text-uppercase small">Recent Searches</p>
				  <a href="#" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
					 <div className="d-flex align-items-center gap-3 osahan-mb-1">
						<i className="lni lni-map-marker text-muted fs-5"></i>
						<div>
						   <h6 className="fw-bold mb-1">Ludhiana</h6>
						   <p className="text-muted text-truncate mb-0 small">87997 Street, 784 Sector, Ludhiana, Punjab</p>
						</div>
					 </div>
				  </a>
			   </div>
			</div>
		</div>
	);
}
export default Location;