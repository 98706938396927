import { useState, useContext, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import CustomBottomSheet from './CustomBottomSheet.js';
import Order from './Order.js';

import { GlobalStateContext } from '../Site.js'
import { showTidio } from '../tools/Tidio.js'

function WelcomeLegacy(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [openOrder, setOpenOrder] = useState(false);
	
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	const onClose = useCallback(() => {
		if(openOrder)
			props.setOpenOrder(true);
	}, [openOrder]);
	
	useEffect(() => {
		if(props.isOpen)
			setOpenOrder(false);
	}, [props.isOpen]);
		
	return(
		<CustomBottomSheet isOpen={props.isOpen} setIsOpen={props.setIsOpen} onClose={onClose} type="auto" id={"welcomelegacy"}>
			<div className="px-4 py-lg-4 pb-4">
				<div className="w-100 d-flex justify-content-center flex-column gap-3">
					<h5 className="text-center">{t("welcomeLegacy.title")}</h5>
					<p className="mb-0">
						{t("welcomeLegacy.content", {returnObjects: true})[0]}<br/>
						{t("welcomeLegacy.content", {returnObjects: true})[1]} <Link className="text-info" onClick={(e) => {setOpenOrder(true); close();}}>{t("welcomeLegacy.link")}</Link>
					</p>
					<p>
						{t("welcomeLegacy.footer", {returnObjects: true})[0]} <span className="fs-5">🍸</span><br/>
						{t("welcomeLegacy.footer", {returnObjects: true})[1]}
					</p>
					<div className="d-flex justify-content-around align-items-center gap-4 flex-column flex-lg-row-reverse">
						<Link className="btn btn-danger fw-bold py-3 px-4 rounded-4 shadow" onClick={(e) => close()}><i className="bi bi-hand-thumbs-up-fill me-2"></i>{t("button.letsgo")}</Link>
						<Link className="btn bg-light-gray fw-bold py-3 px-4 rounded-4 shadow" onClick={(e) => { close(); showTidio(); }}><i className="bi bi-chat-left-fill me-2"></i>{t("contact.title")}</Link>
					</div>
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default WelcomeLegacy;