import { useState, useRef, useEffect } from 'react'

function Map(props) {
	
	const ref = useRef();

	useEffect(() => {
		if((window.google != null) && (props.coordsOnMap != null)) {
			new window.google.maps.Map(ref.current, {
			  center: props.coordsOnMap,
			  zoom: props.coordsOnMap.zoom,
			  options:{
				  disableDefaultUI: true
			  }
			});
		}
	}, [props.coordsOnMap, window.google]);

	return <div ref={ref} id="map" />;
}
export default Map;