import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

import { GlobalStateContext } from '../Site.js'

import AddressItem from './AddressItem.js'

function MyAddresses(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	
	const { t } = useTranslation();
	
	const selectAddress = (address) => {
		globalState.cart.idAddress = address.idAdresse;
		setGlobalState(prevState => ({
			...prevState,
			cart: globalState.cart,
		}));
	};
	
	return(
		<div className="offcanvas offcanvas-end my-cart-width" tabIndex="-1" id="myaddress">
            <div className="offcanvas-header px-4 py-3">
               <h5 className="offcanvas-title fw-bold" id="myaddressLabel">{t("myAddresses.title")}</h5>
               <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body p-0 bg-light">
               <div className="bg-light">
                  <Link className="link-dark py-3 px-4 osahan-location text-decoration-none d-flex align-items-center gap-3 text-start flex-shrink-0" onClick={(e) => { props.setAddressToEdit(null); props.openAddAdress(); }}>
                     <i className="bi bi-plus-circle text-info fs-4"></i>
                     <div className="lh-sm">
                        <h6 className="fw-bold mb-0">{t("myAddresses.addAddress")}</h6>
                     </div>
                  </Link>
               </div>
               <div className="px-4 py-2 bg-light">
			   {globalState?.customer?.adresses.map(a => {
                  return <AddressItem id={"aaflexRadioDefault" + a.idAdresse} address={a} selectAddress={selectAddress} selected={(a.idAdresse == props.addressSelected?.idAdresse)} setAddressToEdit={props.setAddressToEdit} editAddress={props.openAddAdress} key={a.idAdresse} />
			   })}
               </div>
            </div>
            <div className="offcanvas-footer p-4 bg-light">
               <Link className="btn btn-danger fw-bold py-3 px-4 w-100 rounded-4 shadow" data-bs-dismiss="offcanvas">{t("button.resume")}</Link>
            </div>
         </div>
	);
}
export default MyAddresses;