import { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import CustomBottomSheet from './CustomBottomSheet.js';

import { GlobalStateContext } from '../Site.js'

function WelcomeCall(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [navigateTo, setNavigateTo] = useState(null);
	
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	const onClose = useCallback(() => {
		if(navigateTo != null)
			navigate(navigateTo);
	}, [navigateTo]);
	
	const getCustomerName = () => {
		let name = "";
		if(globalState?.customer != null) {
			if((globalState.customer.prenom != null) && (globalState.customer.prenom.length > 1)) {
				name = globalState.customer.prenom;
			}
			else if(globalState.customer.nom != null) {
				name = name + globalState.customer.nom;
			}
		}
		return name;
	};
	
	return(
		<CustomBottomSheet isOpen={props.isOpen} setIsOpen={props.setIsOpen} onClose={onClose} type="auto" id={"welcomecall"}>
			<div className="p-4">
				<div className="w-100 d-flex justify-content-center flex-column gap-3">
					<h5>Hello {getCustomerName()}<span className="fs-3"> 👋</span></h5>
					<p>{t("welcomeCall.content", {returnObjects: true})[0]}<br/>
					{t("welcomeCall.content", {returnObjects: true})[1]} <span className="fs-5">🍸</span></p>
					<p>
						{t("welcomeCall.contact", {returnObjects: true})[0]}
						<span className="d-inline-block ms-2 me-2">
							<b className="bg-orange bg-opacity-75 rounded-circle user-icon" style={{width: '30px', height: '30px'}}><i className="bi bi-chat-left d-flex m-0 h4 text-black" style={{fontSize: '1rem'}}></i></b>
						</span>
						{t("welcomeCall.contact", {returnObjects: true})[1]}
					</p>
					<p className="text-muted mb-1">
						{t("login.accept")}
						<span className="ms-1">
							<Link className="text-info " onClick={(e) => { setNavigateTo(t("cms.privacy.url")); close(); }}>{t("cms.privacy")}</Link>
						</span>
					</p>
					<div className="d-flex justify-content-around">
						<Link className="btn btn-danger fw-bold py-3 px-4 rounded-4 shadow" onClick={(e) => { setNavigateTo("/"); close(); }}><i className="bi bi-hand-thumbs-up-fill me-2"></i>{t("button.letsgo")}</Link>
					</div>
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default WelcomeCall;