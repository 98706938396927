import { Link } from "react-router-dom";
import { showTidio } from '../tools/Tidio.js'

function NavBarIcon(props) {
	
	if(props.type == "cart") {
		return(
			<Link to='/cart' className="link-dark">
				<div className="bg-orange bg-opacity-75 rounded-circle user-icon"><i className="bi bi-basket d-flex m-0 h5 text-black"></i></div>
			</Link>
		);
	}
	else if(props.type == "menu") {
		return(
			<Link className="toggle hc-nav-trigger hc-nav-1" data-bs-toggle="offcanvas" data-bs-target="#navbar-default" aria-controls="navbar-default" aria-expanded="false" aria-label="Toggle navigation">
				<b className="bg-orange bg-opacity-75 rounded-circle user-icon">
					<i className="bi bi-list d-flex m-0 h4 text-black"></i>
				</b>
			</Link>
		);
	}
	else if(props.type == "chat") {
		return(
			<Link className="link-dark" onClick={showTidio}>
				<div className="bg-orange bg-opacity-75 rounded-circle user-icon"><i className="bi bi-chat-left d-flex m-0 h5 text-black"></i></div>
			</Link>
		);
	}
}
export default NavBarIcon;