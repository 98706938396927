import { useState } from 'react'

function QuantitySelector(props) {

	return(
		<div className="input-group border rounded overflow-hidden value d-flex align-items-center">
		  <button className="button-minus btn btn-light btn-sm border-end col" onClick={(e) => { props.setQuantity(Math.max(props.quantity - 1, 0)); e.target.blur(); }}>-</button>
		  <input type="text" value={props.quantity} name="quantity" className={"quantity-field form-control form-control-sm col text-center shadow-none border-0" + (props.theme == "dark" ? " bg-dark text-white" : "")} readOnly/>
		  <button className="button-plus btn btn-light btn-sm border-start col" onClick={(e) => { props.setQuantity(Math.max(props.quantity + 1)); e.target.blur(); }}>+</button>
		</div>
	);
}
export default QuantitySelector;