import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import CMSContent from "../components/CMSContent.js";
import Error404 from "./Error404.js";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'

function Content(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [content, setContent] = useState(null);
	const [error, setError] = useState(false);
	const { cms } = useParams();
	const [idCMS, name] = cms.split("-");
	
	const getCMSContent = () => {
		Api(
			"getCMSById", 
			{
				"id_cms": idCMS,
			},
			(data) => {
				setContent(data.content);
			},
			(idError) => {
				setError(true);
			},
			globalState.token,
			globalState.setToken,
		);
			
	}
	
	useEffect(() => {
		getCMSContent();
	}, [idCMS]);
	
	useEffect(() => {
		props.setReturnPage("");
	}, []);
	
	if(!error) {
		return(
			<>
				<Helmet>
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				<div className="vh-100 my-auto overflow-auto bg">
					<section className="py-4 bg-white">
						<CMSContent content={content} />
					</section>
				</div>
			</>
		);
	}
	else {
		return <Error404 setReturnPage={props.setReturnPage} />
	}
}
export default Content;