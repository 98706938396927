import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"

function AddressItem(props) {
	
	const input = useRef();
	
	const { t } = useTranslation();
	
	useEffect(() => {
		if(props.selected) {
			input.current.click();
		}
	}, [props.selected]);

	return(
		<div className="form-check osahan-radio-box-action osahan-radio-box w-100 bg-white border p-3 my-3 rounded-4">
			<input className="form-check-input" type="radio" name="aaflexRadioDefault" id={props.id} ref={input} onClick={(e) => {props.selectAddress(props.address); }} />
			<label className="form-check-label" htmlFor={props.id}></label>
			<div className="osahan-radio-box-inner">
				<div className="d-flex align-items-center justify-content-between bg-white">
				   <div className="w-80">
					  <div className="d-flex align-items-center gap-3 osahan-mb-1">
						 <i className="bi bi-house fs-5"></i>
						 <div className="overflow-hidden">
							<h6 className="fw-bold mb-1 text-overflow-ellipsis">{props.address.adresse}</h6>
							<p className="text-muted text-truncate mb-0 small">{props.address.cp} {props.address.ville}</p>
						 </div>
					  </div>
				   </div>
				   <div className="ms-auto gap-2 mt-auto text-center small">
					  <Link className="small d-flex align-items-center gap-1 text-info" onClick={(e) => { props.setAddressToEdit(props.address); props.editAddress(); }}><i className="bi bi-pencil-square d-flex"></i>{t("button.edit")}</Link>
				   </div>
				</div>
			</div>
		</div>
	);
}
export default AddressItem;