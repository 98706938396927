import { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast, Slide, Zoom, cssTransition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "animate.css/animate.min.css";

import CustomBottomSheet from './CustomBottomSheet.js'
import QuantitySelector from './QuantitySelector.js';
import ProductImage from './ProductImage.js'

import { GlobalStateContext } from '../Site.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { removeAccents, replaceAll } from '../tools/StringTools.js'
import { showToast } from '../tools/Toast.js';

function ProductDetail(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [isOpen, _setIsOpen] = useState(false);
	const [navigateTo, setNavigateTo] = useState(null);
	const [quantityInCart, setQuantityInCart] = useState(0);
	const [addedInCart, setAddedInCart] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const onClose = useCallback(() => {
		if(navigateTo != null)
			navigate(navigateTo);
	}, [navigateTo]);
	
	const updateQuantity = (quantity) => {
		if(quantity > props.product.quantite) {
			showToast(t("toast.product_out_of_stock"));
		}
		else {
			setQuantityInCart(quantity);
			globalState.cart.updateProduct(
				props.product,
				quantity,
				globalState,
				setGlobalState,
				() => {},
				(product) => {
					if(quantity > quantityInCart) {
						showToast(t("toast.add_product_failed"));
					}
					if(quantity > 0) {
						setQuantityInCart(globalState.cart.getQuantityProduct(props.product));
					}
				}
			);
		}
	}
	
	const toCart = (e) => {
		e.preventDefault();
		if(!window.location.href.includes("/cart"))
			setNavigateTo('/cart');
		setIsOpen(false);
	}
	
	const showAddedToCartToast = () => {
		toast(({ closeToast, toastProps }) => (
			<div className="d-flex gap-3 align-items-center justify-content-center text-success fs-6">
				<i className="bi bi-bag-check-fill fs-5"></i><span>{t("product.addedToCart")}</span>
			</div>
		), {
			containerId: "productAddedContainer"
		});
	}
	
	const footer = () => {
		return (
			<div className="w-100 position-relative">
				<div className="p-3 bg-white w-100 d-flex justify-content-center align-items-center gap-4 product-detail-footer border-top">
					<h4 className="fw-bold mb-0">{currencyFormat(props.product?.prixTTC)}</h4>
					{props.product?.quantite <= 0 &&
						<div className="product-item-out-stock">
							<i className="bi bi-slash-circle m-1"> </i>
							<span>{t("product.out_of_stock")}</span>
						</div>
					}
					{props.product?.quantite > 0 &&
						<Link onClick={(e) => { updateQuantity(1); showAddedToCartToast(); }} className="btn btn-danger fw-bold py-3 px-3 rounded-4 shadow">{t("addToCart.add")}</Link>
					}
				</div>
				{props.product?.quantite > 0 &&
					<div className={"p-3 bg-dark w-100 d-flex justify-content-center align-items-center gap-4 border-top position-absolute bottom-0 product-detail-footer product-detail-footer-cart" + (quantityInCart > 0 ? " in-cart" : "")}>
						<QuantitySelector quantity={quantityInCart} setQuantity={updateQuantity} theme="dark" />
						<Link onClick={toCart} className="btn btn-danger fw-bold py-3 px-3 rounded-4 shadow">{t("product.seeCart")}</Link>
					</div>
				}
			</div>
		);
	}
	
	const toastTransition = cssTransition({
	  enter: 'zoomIn',
	  exit: 'zoomOut',
	  appendPosition: false,
	  collapse: true,
	  collapseDuration: 300
	});
	
	const setIsOpen = (isOpen) => {
		props.setIsOpen(false);
	};
	
	useEffect(() => {
		_setIsOpen(props.isOpen);
	}, [props.isOpen]);
	
	useEffect(() => {
		if(props.product != null)
			setQuantityInCart(globalState?.cart?.getQuantityProduct(props.product) ?? 0);
		setAddedInCart(false);
	}, [props.product]);
	
	return(
		<CustomBottomSheet isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} type="auto" id="product-detail" urlRewrite={t("url.delivery") + "/" + replaceAll(removeAccents(props.product?.nom?.toLowerCase() + "/" + process.env.REACT_APP_CITY.toLowerCase()), ' ', '-').replace(/[^a-zA-Z0-9-_/\//]/g, '') + "/" + props.product?.idProduit} footer={footer()}>
			 <div className="mb-lg-5">
				<div className="overflow-hidden mb-3 mx-2">
				   <div className="big-img-1">
					 <ProductImage className="img-fluid d-block mx-auto" idProduct={props.product?.idProduit} idImage={(props.product?.images?.length > 0 ? props.product.images[0].idImage : null)}/>
				   </div>
				</div>
			 </div>
			 <div className="product-details px-4 pt-4">
				<h1 className="fw-bold">{props.product != null ? props.product.nom : ""}</h1>
				<div className="mt-4" dangerouslySetInnerHTML={{ __html: props.product != null ? props.product.descriptionLongue : "" }}></div>
			 </div>
			 <ToastContainer
				containerId="productAddedContainer"
				enableMultiContainer
				position="bottom-center"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				pauseOnHover={false}
				closeButton={false}
				theme="light"
				style={{
					width: (window.innerWidth / window.innerHeight >= 1 ? "200px" : "100%"),
					bottom: window.innerHeight - document.querySelector("#product-detail div[aria-modal='true'], #product-detail[aria-modal='true'] .modal-dialog")?.getBoundingClientRect().bottom + 90 + "px",
				}}
				transition={cssTransition({
				  enter: "animate__animated animate__bounceIn",
				  exit: "animate__animated animate__bounceOut"
				})}
			/>
		</CustomBottomSheet>
	);
}
export default ProductDetail;