function checkName(strToCheck) {
	if(strToCheck != null) {
		let regName = /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s-]+$/;
		return regName.test(strToCheck);
	}
	return false;
}

function checkEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function formatPhone (phone)  {
	let phoneFormat = phone.replace("+33", "0");
	phoneFormat = phoneFormat.replace("+", "00");
	return phoneFormat;
}

function removeAccents(str) {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function replaceAll(str, search, replace) {
	if(str == null)
		return null;
	let newStr = str;
	while(newStr.indexOf(search) > -1) {
		newStr = newStr.replace(search, replace);
	}
	return newStr;
}

export {checkName, checkEmail, formatPhone, removeAccents, replaceAll}