import { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";

import CustomBottomSheet from './CustomBottomSheet.js';

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js';

function GenerateInvoice(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [loading, setLoading] = useState(true);
	const [invoiceLink, setInvoiceLink] = useState(null);
	const [error, setError] = useState(null);
	const invoiceLinkRef = useRef(null);
	
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	const getInvoice = () => {
		Api(
			"getInvoice",
			{
				"id_commande": props.idOrder,
			},
			(data) => {
				const byteCharacters = atob(data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				console.log(byteArray);
				const blob = new Blob([byteArray], {type: "application/x-pdf"});
				console.log(blob);
				const blobUrl = URL.createObjectURL(blob);
				setInvoiceLink(blobUrl);
				setLoading(false);
				setError(null);
			},
			(idError) => {
				switch(idError) {
					case 235:
						setError({
							title: t("generateInvoice.errorOrderState"),
							subtitle: null
						});
						break;
					case 236:
						setError({
							title: t("generateInvoice.errorOrderCanalTitle"),
							subtitle: t("generateInvoice.errorOrderCanalSubtitle")
						});
						break;
					default:
						setError({
							title: t("generateInvoice.errorMain"),
							subtitle: t("generateInvoice.errorSecondary")
						});
						break;
				}
				setInvoiceLink(null);
				setLoading(false);
			},
			globalState.token,
			globalState.setToken,
		);
	}
	
	useEffect(() => {
		if(props.isOpen) {
			if((invoiceLink == null) || (error != null)) {
				setLoading(true);
				getInvoice();
			}
		}
	}, [props.isOpen]);
	
	useEffect(() => {
		setInvoiceLink(null);
	}, [props.idOrder]);
	
	useEffect(() => {
		if((invoiceLink != null) && (invoiceLinkRef?.current != null) && props.isOpen) {
			invoiceLinkRef?.current?.click();
		}
	}, [invoiceLink, invoiceLinkRef?.current]);
	
	return(
		<CustomBottomSheet isOpen={props.isOpen} setIsOpen={props.setIsOpen} type="auto" id={"generateInvoice"}>
			<div className="px-4 py-4">
				<div className="w-100 d-flex justify-content-center align-items-center flex-column gap-4 text-center">
					{loading &&
						<>
							<PuffLoader 
								color="#1D71B8"
								size="4rem"
							/>
							<h6>{t("generateInvoice.loading")}</h6>
						</>
					}
					{!loading && error == null && invoiceLink != null &&
						<>
							<i className="bi bi-check-circle-fill text-success" style={{fontSize: "4rem", lineHeight: 0}}></i>
							<h6 className="text-success">{t("generateInvoice.ready")}</h6>
							<a href={invoiceLink} download="facture_sos_soiree.pdf" className="text-muted" ref={invoiceLinkRef}>{t("generateInvoice.downloadInvoice")}</a>
						</>
					}
					{!loading && invoiceLink == null &&
						<>
							<i className="bi bi-x-circle-fill text-error" style={{fontSize: "4rem", lineHeight: 0}}></i>
							<div className="d-flex flex-column align-items-center">
								<h6>{error?.title ?? t("generateInvoice.errorMain")}</h6>
								{error?.subtitle != null &&
									<p className="text-muted">{error.subtitle}</p>
								}
							</div>
						</>
					}
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default GenerateInvoice;