import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

import { GlobalStateContext } from '../Site.js'

function CookieBanner(props) {
	
	const VERSION = 1;
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [visible, setVisible] = useState(false);
	const [warning, setWarning] = useState(false);
	
	const { t } = useTranslation();
	
	const acceptAndClose = () => {
		const cookies = new Cookies(null, { path: '/' });
		cookies.set("cav", VERSION, { expires: new Date(new Date().getTime()+(365*24*60*60*1000)) });
		setGlobalState(prevState => ({
			...prevState,
			cookiesAcceptanceVersion: VERSION
		}));
	};
	
	const testCookieWriting = () => {
		try {
			const cookies = new Cookies(null, { path: '/' });
			cookies.set("tst", true, { expires: new Date(new Date().getTime()+1000) });
			if(!cookies.get("tst") ?? false) {
				setWarning(true);
				setGlobalState(prevState => ({
					...prevState,
					cookiesDisabled: true
				}));
			}
			else
				cookies.remove("tst");
		}
		catch(error) {
			setWarning(true);
		}
	};
	
	useEffect(() => {
		if(globalState?.cookiesAcceptanceVersion < VERSION)
			setVisible(true);
		else
			setVisible(false);
	}, [globalState?.cookiesAcceptanceVersion]);
	
	useEffect(() => {
		if(visible)
			testCookieWriting();
	}, [visible]);
	
	return(
		<div className={"cookie-banner position-fixed bottom-0 d-flex flex-column w-100 rounded-top-4 pt-3 bg-info mt-auto mh-100 overflow-auto" + (visible ? " visible" : "")}>
			<div className="d-flex w-100 justify-content-end px-3">
				<Link className="text-white" onClick={(e) => acceptAndClose()}>{t("cookieBanner.accept")} <i className="bi bi-chevron-right pt-1"></i></Link>
			</div>
			<div className="cookie-banner-content d-flex gap-4 justify-content-center text-center px-3">
				<img className="img-fluid" src={require("../img/mini_pizza.png")} alt="..."/>
				<div className="d-flex flex-column align-items-center">
					<h6 className="text-center">{t("cookieBanner.title")} {t("cookieBanner.subtitle")}</h6>
					<p className="mt-4">{t("cookieBanner.content")}</p>
					<p>{t("cookieBanner.privacy")}<Link to={t("cms.privacy.url")} className="ms-1" onClick={(e) => acceptAndClose()}>{t("cms.privacy").toLowerCase()}</Link></p>
				</div>
			</div>
			{warning &&
				<div className="w-100 bg-danger text-dark p-3 text-center">
					<b>{t("cookieBanner.warning")}</b>
				</div>
			}
		</div>
	);
}
export default CookieBanner;
