import { useState } from 'react'
import { Link } from "react-router-dom"
import BeatLoader from "react-spinners/BeatLoader";

function LoadingButton(props) {

	return(
		<Link 
			className={"btn btn-danger fw-bold py-3 px-4 w-100 rounded-4 shadow loading-button" + (props.loading ? " loading" : "") + (props.className != null ? " " + props.className : "")}
			onClick={props.onClick}
			disabled={props.loading}
		>
			{!props.loading ? props.title : ""}
			{props.loading &&
				<BeatLoader className="d-flex justify-content-center align-items-center h-100 w-100" color="#FFF"/>
			}
		</Link>
	);
}
export default LoadingButton;