import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastContainerBottomCenter() {
	return(
		<ToastContainer
			containerId="default"
			enableMultiContainer
			position="bottom-center"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover={false}
			theme="dark"
		/>
	);
}

function showToast(message) {
	toast(message, { containerId: "default" });
}

export { ToastContainerBottomCenter, showToast }