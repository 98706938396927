import { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { replaceAll } from '../tools/StringTools.js'
import $ from 'jquery'
window.jQuery = $;
window.$ = $;

function CustomBottomSheet({ children, isOpen, setIsOpen, title, type, id, urlRewrite, footer, onClose, noPadding, autoWidth, dismissable = true }) {
	
	const [_type, setType] = useState("bottomSheet");
	
	const bottomSheet = useRef();
	const modal = useRef();
	const sheet = useRef();

	const navigate = useNavigate();
	const location = useLocation();
	
	const close = () => {
		setIsOpen(false);
	}
	
	const getType = () => {
		if(type == "auto") {
			let screenRatio = window.innerWidth / window.innerHeight;
			if(screenRatio < 1)
				return "bottomSheet";
			else
				return "modal";
		}
		else {
			return type;
		}
	}
	
	const backButton = useCallback(() => {
		handleBackButton();
		window.removeEventListener("popstate", backButton);
	}, []);
	
	const handleBackButton = useCallback(() => {
		close();
	}, []);
	
	const bottomSheetStartClosing = () => {
		if(dismissable) {
			window.removeEventListener("popstate", backButton);
			if(window.location.href.includes("view/")) {
				let afterBack = (_) => {
					if(onClose != null)
						onClose();
					window.removeEventListener("popstate", afterBack);
				};
				window.addEventListener("popstate", afterBack);
				window.history.back();
			}
			else if(onClose != null)
				onClose();
		}
		else {
			setIsOpen(true);
		}
	};
	
	useEffect(() => {
		if(type != null) {
			if(type == "auto") {
				let screenRatio = window.innerWidth / window.innerHeight;
				if(screenRatio < 1)
					setType("bottomSheet");
				else
					setType("modal");
			}
			else {
				setType(type);
			}
		}
	}, [type]);
	
	useEffect(() => {
		if(isOpen) {
			if(_type == "modal") {
				$("#" + id).modal('show');
				$("#" + id).off("hidden.bs.modal").on("hidden.bs.modal", function () {
					setIsOpen(false);
					if(onClose != null)
						onClose();
				});
			}
			
			window.addEventListener("popstate", backButton);
			let newPath = "/view/";
			if(urlRewrite != null)
				newPath = newPath + urlRewrite;
			else
				newPath = newPath + id;
			if(location.pathname.includes(newPath)) {
				let viewPath = location.pathname;
				navigate(location.pathname.replaceAll(newPath, "") == "" ? "/" : location.pathname.replaceAll(newPath, ""));
				navigate(viewPath);
			}
			else {
				navigate((location.pathname == "/" ? "" : location.pathname) + newPath);
			}
		}
		else {
			if(_type == "modal") {
				if(dismissable) {
					$("#" + id).off("hide.bs.modal").on("hide.bs.modal", function (e) {});
					$("#" + id).modal('hide');
					window.removeEventListener("popstate", backButton);
					if(window.location.href.includes("view/")) {
						window.history.back();
					}
				}
				else {
					setIsOpen(true);
				}
			}
		}
	}, [isOpen]);
	
	useEffect(() => {
		if(_type == "modal") {
			$("#" + id).off("hidden.bs.modal").on("hidden.bs.modal", function () {
				setIsOpen(false);
				if(onClose != null)
					onClose();
			});
		}
	}, [onClose]);
	
	useEffect(() => {
		if(_type == "modal") {
			$("#" + id).off("hide.bs.modal").on("hide.bs.modal", function (e) {
				if(!dismissable)
					e.preventDefault();
			});
			window.removeEventListener("popstate", backButton);
			window.addEventListener("popstate", backButton);
		}
	}, [dismissable]);

	const inner = () => {
		return (
			<>
				<div className={"d-none d-lg-flex justify-content-end mb-2 d-block p-4 top-0 end-0 w-100 " + (_type == "modal" ? "position-absolute" : " position-fixed")}>
					{dismissable &&
						<button _type="button" className="btn-close" onClick={(e) => close()} {... (_type == "modal" ? {"data-bs-dismiss":"modal"} : {})}></button>
					}
				</div>
				{title &&
					<div className="p-4 text-center mb-2">
						<h5 className="offcanvas-title fw-bold">{title}</h5>
					</div>
				}
				<section className="d-flex flex-column overflow-hidden">
					<div className={"container flex-grow-1" + (_type == "modal" ? " overflow-auto": "")}>
					   <div className="row">
						  <div className={"col-12 " + (noPadding ? "p-0" : "py-3")}>
							{children}
						  </div>
					   </div>
					</div>
					{_type == "modal" && 
						<div className="w-100">
							{footer}
						</div>
					}
				</section>
			</>
		);
	};

	return(
		<div ref={bottomSheet}>
			{((_type == null) || (_type == "bottomSheet")) &&
			<BottomSheet id={id} className="customBottomSheet" open={isOpen} onDismiss={() => {if(!dismissable) sheet.current.snapTo(({ maxHeight }) => maxHeight); else close();}} blocking={true} expandOnContentDrag={true} footer={footer} onSpringStart={(e) => { if(e.type == 'CLOSE') bottomSheetStartClosing(); }}  ref={sheet}>
					{inner()}
				</BottomSheet>
			}
			{(_type == "modal") &&
				<div className={"modal fade overflow-hidden"} id={id} tabIndex="-1" aria-hidden="true" ref={modal} >
					<div className={"modal-dialog modal-dialog-centered " + (!autoWidth  ? "modal-lg" : "")}>
						<div className="modal-content border-0 rounded-4 overflow-hidden mh-100">
							{inner()}
						</div>
					</div>
				</div>
			}
		</div>
	);
}
export default CustomBottomSheet;