import { useContext, useEffect, useState } from 'react'
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from 'react-i18next';

function CheckPayment(props) {
		
	const stripe = useStripe();
	const { t } = useTranslation();
	
	const retrievePaymentIntent = () => {
		stripe.retrievePaymentIntent(props.clientSecret).then(({ paymentIntent }) => {
			switch(paymentIntent.status) {
				case "succeeded":
					props.paymentReturn();
					break;
				case "requires_payment_method":
					props.setPaymentError(prevState => ({
						...prevState,
						isOpen: true,
						message: t("payment.paymentRefused"),
						code: paymentIntent.last_payment_error?.code
					}));
					break;
				default:
					props.setPaymentError(prevState => ({
						...prevState,
						isOpen: true,
						message: t("toast.oopsError"),
						code: paymentIntent.last_payment_error?.code ?? paymentIntent.status
					}));
					break;
			}
		});
	};
	
	useEffect(() => {
		if(stripe != null)
			retrievePaymentIntent();
	}, [stripe]);
}

export default CheckPayment;
