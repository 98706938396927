import React from 'react'
import Cookies from 'universal-cookie'
import _ from "lodash";
import dateFormat from "dateformat"

import { Api } from '../API.js';

export default class Cart {
	
	constructor(idCart, idAddress, products, deliveryFee, bags, voucher, deliveryTime) {
		this.idCart = idCart;
		this.idAddress = idAddress;
		this.products = products;
		this.deliveryFee = deliveryFee;
		this.bags = bags;
		this.voucher = voucher;
		this.deliveryTime = deliveryTime;
	}
	
	getQuantityProduct(product) {
		var quantity = 0;
		this.products.forEach((p, i) => {
			if(p.product.idProduit == product.idProduit)
				quantity = quantity + p.quantity;
		});
		return quantity;
	}
	
	updateProduct(product, quantity, globalState, setGlobalState, onSuccess = () => {}, onFailure = (p) => {}) {
		if(quantity > 0) {
			if(this.getQuantityProduct(product) > 0) {
				this.products.forEach((p, i) => {
					if(p.product.idProduit == product.idProduit)
						this.products[i].quantity = quantity;
				});
			}
			else {
				this.products.push({
					product: product,
					quantity: quantity
				});
			}
		}
		else {
			this.products.forEach((p, i) => {
				if(p.product.idProduit == product.idProduit)
					this.products.splice(i, 1);
			});
		}
		
		this.updateCart(
			false,
			globalState,
			setGlobalState,
			(cart) => {
				let productDeleted = true;
				cart.products.forEach((p, i) => {
					if(p.product.idProduit == product.idProduit) {
						productDeleted = false;
						if((p.product.quantite < quantity) || (p.product.active == 0)) {
							onFailure(p.product);
							return;
						}
					}
				});
				if(productDeleted)
					onFailure(product);
				else
					onSuccess();
			},
			(idError) => {}
		);
	}
	
	updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure, retry = false) {
		let produits = []
		this.products.forEach((p, i) => {
			produits.push({ idProduit: p.product.idProduit, quantite: p.quantity });
		});
		let params = {
			"id_client": (globalState.customer != null ? globalState.customer.idClient : 0),
			"id_adresse_facturation": this.idAddress,
			"id_adresse_livraison": this.idAddress,
			"produits": JSON.stringify(produits),
			"nb_sac_ajout": this.bags,
			"nb_sac_recup": 0,
			"id_bon_reduction": this.voucher?.idBonReduction ?? 0,
			"id_indication_livraison": 0,
			"full_check": (fullCheck ? 1 : 0)
		};
		if(this.deliveryTime != null) {
			params = {
				...params,
				"heure_prevue": dateFormat(this.deliveryTime, "dd/mm/yyyy HH:MM")
			}
		}
		if(this.idCart > 0) {
			Api(
				"updatePanier",
				{
					...params,
					"id_panier": this.idCart,
				},
				(data) => {
					if(data) {
						setGlobalState(prevState => ({
							...prevState,
							cart: _.cloneDeep(this)
						}));
						onSuccess(this);
					}
					else {
						onFailure(0);
					}
				},
				(idError) => {
					if(!retry) {
						switch(idError) {
							case 34:
							case 35:
								this.checkProductsStocks(
									globalState,
									setGlobalState,
									(cartUpdated) => {
										this.updateCart(
											fullCheck, 
											globalState, 
											setGlobalState, 
											(cart) => {
												if(fullCheck)
													onFailure(idError);
												else
													onSuccess(cart);
											}, 
											onFailure,
											true
										);
									},
									onFailure
								);
								break;
							case 13:
							case 14:
							case 15:
							case 16:
							case 17:
							case 18:
							case 122:
								this.voucher = null;
								this.updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure, true);
								break;
							case 29:
								this.idCart = 0;
								this.updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure);
								break;
							default:
								onFailure(idError);
								break;
						}
					}
					else if(!fullCheck) {
						this.idCart = 0;
						this.updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure);
					}
					else {
						onFailure(idError);
					}
				},
				globalState.token,
				globalState.setToken
			);
		}
		else {
			Api(
				"addPanier",
				params,
				(data) => {
					if(Number.isInteger(data)) {
						this.idCart = data;
						const cookies = new Cookies(null, { path: '/' });
						cookies.set("idCart", this.idCart, { expires: new Date(new Date().getTime()+(365*24*60*60*1000)) });
						setGlobalState(prevState => ({
							...prevState,
							cart: this
						}));
						onSuccess(this);
					}
					else {
						onFailure(0);
					}
				},
				(idError) => {
					if(!retry) {
						switch(idError) {
							case 34:
							case 35:
								this.checkProductsStocks(
									globalState,
									setGlobalState,
									(cartUpdated) => {
										this.updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure, true);
									},
									onFailure
								);
								break;
							case 13:
							case 14:
							case 15:
							case 16:
							case 17:
							case 18:
							case 122:
								this.voucher = null;
								this.updateCart(fullCheck, globalState, setGlobalState, onSuccess, onFailure, true);
								break;
							default:
								onFailure(idError);
								break;
						}
					}
					else {
						onFailure(idError);
					}
				},
				globalState.token,
				globalState.setToken
			);
		}
	}
	
	checkProductsStocks(globalState, setGlobalState, onSuccess, onFailure) {
		if(this.products.length == 0) {
			onSuccess(false);
		}
		else {
			let ids = [];
			this.products.forEach((p, i) => {
				ids.push(p.product.idProduit);
			});
			Api(
				"getStockProduits",
				{
					"produits": JSON.stringify(ids),
				},
				(data) => {
					if(Array.isArray(data)) {
						let cartUpdated = false;
						data.forEach((s, i) => {
							this.products.forEach((p, j) => {
								if(s.idProduit == p.product.idProduit) {
									if((s.quantite <= 0) || (s.active == 0)) {
										this.products.splice(j, 1);
										cartUpdated = true;
									}
									else if(s.quantite < p.quantity) {
										this.products[j].quantity = s.quantite;
										this.products[j].product.quantite = s.quantite;
										cartUpdated = true;
									}
								}
							});
						});
						onSuccess(cartUpdated);
					}
					else {
						onFailure(0);
					}
				},
				(idError) => {
					onFailure(idError);
				},
				globalState.token,
				globalState.setToken
			);
		}
	}
	
	getDeliveryMinAndFee(globalState, setGlobalState, onSuccess, onFailure) {
		Api(
			"getMinimumCommande",
			{
				"id_adresse_livraison": this.idAddress,
			},
			(data) => {
				this.deliveryFee = data.fraisLivraison;
				onSuccess(data);
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	/* LEGACY */
	getBags(globalState, setGlobalState, onSuccess, onFailure) {
		Api(
			"getNbSacAjout",
			{
				"id_panier": this.idCart
			},
			(data) => {
				this.bags = data;
				onSuccess();
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	getVoucher(globalState, onSuccess, onFailure) {
		Api(
			"getBonReductionPanier",
			{
				"id_panier": this.idCart,
			},
			(data) => {
				if(data?.idBonReduction > 0) {
					this.voucher = data;
					onSuccess();
				}
				else
					onFailure(0);
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	checkVoucher(code, globalState, onSuccess, onFailure) {
		Api(
			"checkBonReduction",
			{
				"code": code
			},
			(data) => {
				if(data?.idBonReduction > 0) {
					this.voucher = data;
					onSuccess(data);
				}
				else
					onFailure(0);
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	getTotalProducts() {
		let total = 0.0;
		this.products.forEach((p, i) => {
			total = total + (p.product.prixTTC * p.quantity);
		});
		return total;
	}
	
	getTotalDiscount() {
		let discount = 0.0;
		if(this.voucher != null) {
			if(this.voucher.reduction != null) {
				if(this.voucher.reduction.typeReduction == 1) {
					discount = this.getTotalProducts() * ((this.voucher.reduction.valeur ?? 0 / 100));
				}
				else if(this.voucher.reduction.typeReduction == 2) {
					discount = this.voucher.reduction.valeur ?? 0;
				}
			}
		}
		return discount;
	}
	
	getTotal() {
		let total = this.getTotalProducts() - this.getTotalDiscount();
		if(this.deliveryFee != null)
			total = total + this.deliveryFee;
		return total;
	}
	
	getNbProducts() {
		let nb = 0;
		this.products.forEach((p, i) => {
			nb = nb + p.quantity;
		});
		return nb;
	}
	
	getAddress(globalState) {
		let address = null;
		if(this.idAddress > 0) {
			globalState?.customer?.adresses.forEach((a, i) => {
				if(a.idAdresse == this.idAddress)
					address = a;
			});
		}
		return address;
	}
	
	containsAlcohol() {
		let alcohol = false;
		this.products.forEach((p) => {
			if(p.product.alcool)
				alcohol = true;
		});
		return alcohol;
	}
	
	static getCart(idCart, globalState, onSuccess, onFailure) {
		Api(
			"getPanier",
			{
				"id_panier": idCart,
				"with_produits_details": 1
			},
			(data) => {
				let products = [];
				data.produits.forEach((p, i) => {
					products.push({
						product: p.produit,
						quantity: p.quantite
					});
				});
				let cart = new Cart(idCart, data.idAdresseLivraison, products, null, 0, null, (data.heurePrevue != null && data.heurePrevue.date != null ? new Date(data.heurePrevue.date?.split(".")[0]?.replace(/-/g, "/")) : null));
				if(data.idBonReduction > 0) {
					cart.getVoucher(
						globalState,
						() => {
							onSuccess(cart);
						},
						(_) => {
							onSuccess(cart);
						}
					);
				}
				else {
					onSuccess(cart);
				}
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	static emptyCart() {
		return new Cart(0, 0, [], 0, 0, null);
	}
}