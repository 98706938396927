import _ from "lodash";
import dateFormat from "dateformat"

import { Api } from '../API.js';

export default class UserLocation {
	
	constructor(street, postalCode, city, label = null, deliveryTime = null, idAddress = 0, idError = 0, nextOpening = null) {
		if((label == null) && (street != null) && (city != null)) 
			this.label = street + ", " + city;
		else
			this.label = label;
		this.street = street;
		this.postalCode = postalCode;
		this.city = city;
		this.deliveryTime = deliveryTime;
		this.deliveryTimeUpdate = null;
		this.idAddress = idAddress;
		this.idError = idError;
		this.nextOpening = null;
	}
	
	getDeliveryTime(globalState, setGlobalState, onSuccess = (_) => {}, onFailure = (_) => {}) {
		Api(
			"getMeilleurDelai", 
			{
				"rue": globalState.userLocation.street,
				"cp": globalState.userLocation.postalCode,
				"ville": globalState.userLocation.city,
			},
			(data) => {
				let deliveryTime = null;
				if(data.date != null) {
					deliveryTime = new Date(data.date?.split(".")[0]?.replace(/-/g, "/"));
					if(deliveryTime.toString() != "Invalid Date") {
						this.deliveryTime = deliveryTime;
						this.deliveryTimeUpdate = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
						let newUserLocation = _.cloneDeep(this);
						setGlobalState(prevState => ({
							...prevState,
							userLocation: newUserLocation,
						}));
						onSuccess(deliveryTime);
					}
					else
						onFailure(0);
				}
				else
					onFailure(0);
			},
			(idError) => {
				let newUserLocation = _.cloneDeep(this);
				newUserLocation.deliveryTime = null;
				newUserLocation.idError = idError;
				newUserLocation.deliveryTimeUpdate = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
				if(idError == 73) {			
					this.getNextOpening(
						globalState,
						setGlobalState,
						(nextOpening) => {
							newUserLocation.nextOpening = nextOpening;
							setGlobalState(prevState => ({
								...prevState,
								userLocation: newUserLocation,
							}));
						},
						(_) => {
							setGlobalState(prevState => ({
								...prevState,
								userLocation: newUserLocation,
							}));
						}
					);
				}
				else {
					setGlobalState(prevState => ({
						...prevState,
						userLocation: newUserLocation,
					}));
				}
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken,
		);
	}
	
	getNextOpening(globalState, setGlobalState, onSuccess = (_) => {}, onFailure = (_) => {}) {
		Api(
			"getProchainHoraire", 
			{},
			(data) => {
				let nextOpening = null;
				if(data?.date != null) {
					nextOpening = new Date(data.date?.split(".")[0]?.replace(/-/g, "/"));
				}
				if(nextOpening != null)
					onSuccess(nextOpening);
				else
					onFailure(0);
			},
			(idError) => {
				onFailure(idError);
			},
			globalState.token,
			globalState.setToken,
		);
	}
	
	getDeliveryTimeLabel(t) {
		if(this.deliveryTime != null) {
			let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
			let diffInMinutes = Math.round((this.deliveryTime.getTime() - now.getTime()) / 1000 / 60);
			if(diffInMinutes <= 60) {
				return t("global.deliveryIn") + " " + diffInMinutes + " minutes";
			}
			else if((diffInMinutes < 1440) && ((this.deliveryTime.getHours() > now.getHours()) || (this.deliveryTime.getHours() <= 6))) {
				return t("global.deliveryAt") + " " + dateFormat(this.deliveryTime, "HH:MM");
			}
			else {
				return t("global.delivery") + " " + this.deliveryTime.toLocaleDateString("fr-FR", { weekday: 'long' }) + " " + t("global.dateAt") + " " + dateFormat(this.deliveryTime, "HH:MM");
			}
		}
		else {
			return this.getErrorLabel(t);
		}
	}
	
	getLabel(t) {
		if((this.deliveryTime != null) && (this.label != null)) {
			return this.label;
		}
		else {
			if((this.idError == 73) && (this.nextOpening != null)) {
				let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
				if(dateFormat(now, "dd/mm/yyyy") == dateFormat(this.nextOpening, "dd/mm/yyyy"))
					return t("global.dateBackAt") + " " + dateFormat(this.nextOpening, "HH:MM");
				else
					return t("global.dateBack") + " " + this.nextOpening.toLocaleDateString("fr-FR", { weekday: 'long' }) + " " + t("global.dateAt") + " " + dateFormat(this.nextOpening, "HH:MM");
			}
		}
	}
	
	getErrorLabel(t) {
		if(this.idError > 0) {
			if(this.idError == 73) {
				return t("global.serviceClosed");
			}
			else if(this.idError == 66) {
				return t("global.addressOutsideRange");
			}
			else {
				return t("global.errorDeliveryTime");
			}
		}
	}
	
	isDeliveryTimeObsolete() {
		if((this.deliveryTime != null) || (this.idError > 0)) {
			if(this.deliveryTimeUpdate != null) {
				let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
				let diffInMinutes = Math.round((now.getTime() - this.deliveryTimeUpdate.getTime()) / 1000 / 60);
				return diffInMinutes > 5;
			}
			else
				return true;
		}
		else
			return false;
	}
	
	static defaultUserLocation() {
		return new UserLocation("7 rue de l'industrie", "74000", "Annecy", "Annecy gare");
	}
	
	static fromAddress(address) {
		return new UserLocation(address.adresse, address.cp, address.ville, null, null, address.idAdresse);
	}
}