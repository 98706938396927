import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import CustomBottomSheet from './CustomBottomSheet.js';

function PaymentError(props) {
	
	const [accepted, setAccepted] = useState(false);
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	return(
		<CustomBottomSheet isOpen={props.isOpen} setIsOpen={props.setIsOpen} type="modal" id={"payment-error-" + props.code}>
			<div className="px-4 pt-5">
				<div className="w-100 d-flex justify-content-center align-items-center flex-column gap-4">
					<span>{props.message}</span>
					<Link className="btn btn-danger fw-bold p-3 rounded-4 shadow" onClick={(e) => close()}>OK</Link>
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default PaymentError;