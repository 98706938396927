import React, { useEffect, useState, useContext, useCallback } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { GlobalStateContext } from '../Site.js';
import { currencyFormat } from '../tools/NumberTools.js';
import { checkEmail } from '../tools/StringTools.js';
import LoadingButton from './LoadingButton.js';

export default function CheckoutForm(props) {
	const stripe = useStripe();
	const elements = useElements();

	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [message, setMessage] = useState(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [paymentSelected, setPaymentSelected] = useState(null);
	
	const { t } = useTranslation();
	
	const paymentElementOptions = {
		layout: "tabs",
		defaultValues: {
			billingDetails: {
				name: globalState.cart.getAddress(globalState).prenom + " " + globalState.cart.getAddress(globalState).nom,
				email: checkEmail(globalState.customer.mail ?? "") ? globalState.customer.mail : "",
				phone: globalState.cart.getAddress(globalState).telephone,
			}
		}
	}
	
	const paymentElementReady = useCallback(() => {
		props.setPaymentLoading(false);
	});
	
	const paymentElementLoadError = (e) => {
		props.setPaymentError(prevState => ({
			...prevState,
			isOpen: true,
			message: t("toast.oopsError"),
			code: (e.error.message_code ?? e.error.type)
		}));
		props.onLoadError();
	};
	
	const paymentElementChange = useCallback((e) => {
		setPaymentSelected(e.value?.type ?? null);
	});

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
		  // Stripe.js hasn't yet loaded.
		  // Make sure to disable form submission until Stripe.js has loaded.
		  return;
		}
		
		if(paymentSelected == "external_postfinance") {
			props.setPaymentError(prevState => ({
				...prevState,
				isOpen: true,
				message: t("payment.paymentNotAvailable"),
				code: "postfinance_" + globalState.cart?.idCart
			}));
			return;
		}

		setButtonLoading(true);

		stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: props.returnUrl,
			},
			redirect: 'if_required',
		})
		.then(function(result) {
			if(result.error) {
				if(result.error.type != 'validation_error') {
					// In case of validation_error, error will be displayed in the form
					props.setPaymentError(prevState => ({
						...prevState,
						isOpen: true,
						message: result.error.message,
						code: result.error.code
					}));
				}
			}
			else {
				props.paymentReturn();
			}
			setButtonLoading(false);
		});
	};
	
	useEffect(() => {
		if (!stripe) {
			return;
		}

		if (!props.clientSecret) {
			return;
		}
	}, [stripe]);

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" options={paymentElementOptions} onReady={paymentElementReady} onLoadError={paymentElementLoadError} onChange={paymentElementChange} className={props.paymentLoading ? "invisible h-0" : "p-4 bg-white rounded-4 shadow"} />
			{!props.paymentLoading &&
				<LoadingButton className={"d-flex align-items-center justify-content-center justify-content-lg-between mt-3" + (!stripe || !elements ? " cursor-default" : "")} onClick={handleSubmit} loading={buttonLoading}
					title={
						<>
							<span className="d-none d-lg-block">{currencyFormat(globalState?.cart?.getTotal())}</span>
							<span>{t("payment.proceed")}<i className="bi bi-check-lg ms-2 fs-5"></i></span>
						</>
					}
				>
				</LoadingButton>
			}
		</form>
	);
}