import { useState, useEffect, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import CustomBottomSheet from './CustomBottomSheet.js';
import Icon18 from "./Icon18.js";

import { GlobalStateContext } from '../Site.js'

function DeliveryConfirm(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [accepted, setAccepted] = useState(false);
	const { t } = useTranslation();
	
	const close = () => {
		props.setIsOpen(false);
	}
	
	const onClose = useCallback(() => {
		if(accepted)
			props.acceptDeliveryConfirm();
	}, [accepted]);
	
	useEffect(() => {
		if(onClose != null)
			close();
	}, [onClose]);
	
	return(
		<CustomBottomSheet isOpen={props.isOpen} setIsOpen={props.setIsOpen} onClose={onClose} type="auto" id={"deliveryConfirm"}>
			<div className="px-4">
				<div className="w-100 d-flex justify-content-center align-items-center flex-column">
					<i className="bi bi-house-check-fill" style={{fontSize: "5rem"}}></i>
					<h6 className="mb-4">{t("deliveryConfirm.title")} <span className="fs-5">👍</span></h6>
					<div className="d-flex gap-4 px-4 flex-wrap w-100 mb-3">
						<div className="d-flex flex-column flex-basis-0 flex-grow-1 delivery-confirm-item">
							<span className="text-muted small">{t("deliveryConfirm.address")}</span>
							<div className="d-flex w-100 border-dark py-2 px-3 rounded-4 align-items-center gap-3">
								<i className="bi bi-house-fill fs-5"></i>
								<span className="fs-6">{props.address?.adresse + ", " + props.address?.ville}</span>
							</div>
						</div>
						<div className="d-flex flex-column flex-basis-0 flex-grow-1 delivery-confirm-item">
							<span className="text-muted small">{t("deliveryConfirm.phone")}</span>
							<div className="d-flex w-100 border-dark py-2 px-3 rounded-4 align-items-center gap-3">
								<i className="bi bi-telephone-fill fs-5"></i>
								<span className="fs-6">{props.address?.telephone}</span>
							</div>
						</div>
					</div>
					{globalState?.cart?.containsAlcohol() &&
						<div className="d-flex justify-content-center align-items-center p-3 bg-light-gray rounded-2 mb-3 mt-3">
							<i className="bi bi-house-exclamation" style={{fontSize: "2rem"}}></i>
							<p className="ms-3 mb-0">{t("deliveryConfirm.alcohol")}</p>
						</div>
					}
					<div className="d-flex w-100 justify-content-around align-items-center">
						<Link className="text-black text-uppercase text-center" onClick={(e) => close()}><i className="bi bi-arrow-left me-1"></i> {t("button.edit")}</Link>
						<Link className="btn btn-danger fw-bold py-3 px-3 rounded-4 shadow ms-4" onClick={(e) => setAccepted(true)}>{t("button.acceptAndContinue")}</Link>
					</div>
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default DeliveryConfirm;