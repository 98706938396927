import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from "react-router-dom";

function Concept(props) {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		props.setReturnPage("");
		props.setPageTitle(t("concept.title"));
	}, []);
	
	return(
		<div className="vh-100 my-auto overflow-auto bg">
			<section className="py-4 bg-white">
				<div className="container px-4">
				   <div className="row align-items-center gap-4 flex-column flex-lg-row">
					  <div className="col-5 col-lg-3"><img className="img-fluid rounded-4" src={require("../img/idee.jpg")} alt="..."/></div>
					  <div className="col-9 col-lg-8">
						 <h5 className="fw-bold mb-3">{t("concept.idea.title")}</h5>
						 <h6 className="mb-3">{t("concept.idea.subtitle")}</h6>
						 {t("concept.idea.content", {returnObjects: true}).map((c, i) => {
							return <p className="fw-normal text-muted" key={i}>{c}</p>
						 })}
						 <Link to="/faq" className="text-info">{t("concept.idea.link")}</Link>
					  </div>
				   </div>
				</div>
			</section>
			<section className="py-4">
				<div className="container px-4">
				   <div className="row align-items-center gap-4 flex-column flex-lg-row-reverse">
						<div className="col-5 col-lg-3"><img className="img-fluid rounded-4" src={require("../img/fonctionnement.jpg")} alt="..."/></div>
						<div className="col-9 col-lg-8">
							<h5 className="fw-bold mb-3">{t("concept.how.title")}</h5>
							<h6 className="mb-3">{t("concept.how.subtitle")}</h6>
							{t("concept.how.content", {returnObjects: true}).map((c, i) => {
								return <p className="fw-normal text-muted" key={i}>{c}</p>
							})}
							<Link data-bs-toggle="modal" data-bs-target={"#" + t("hours.url")} className="text-info">{t("concept.how.link")}</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="py-4 bg-white">
				<div className="container px-4">
				   <div className="row align-items-center gap-4 flex-column flex-lg-row">
					  <div className="col-5 col-lg-3"><img className="img-fluid rounded-4" src={require("../img/team.jpg")} alt="..."/></div>
					  <div className="col-9 col-lg-8">
						 <h5 className="fw-bold mb-3">{t("concept.about.title")}</h5>
						 <h6 className="mb-3"><Trans i18nKey="concept.about.subtitle" components={{ strike: <strike /> }} /></h6>
						 {t("concept.about.content", {returnObjects: true}).map((c, i) => {
							return <p className="fw-normal text-muted" key={i}>{c}</p>
						 })}
						 <b>
							{t("concept.about.follow")}
							<Link to={t("concept.about.facebook")} className="ms-2 text-info fs-4"><i className="bi bi-facebook"></i></Link>
							<Link to={t("concept.about.instagram")} className="ms-2 text-info fs-4"><i className="bi bi-instagram"></i></Link>
						 </b>
					  </div>
				   </div>
				</div>
			</section>
		</div>
	);
}
export default Concept;