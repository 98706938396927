import { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat"

function Hours(props) {

	const closeButton = useRef();
	const { t } = useTranslation();
		
	return(
		<div className="modal fade" id={t("hours.url")} tabIndex="-1" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg">
               <div className="modal-content border-0 rounded-4 overflow-hidden">
					<div className="modal-body p-4">
						<button type="button" className="btn-close float-end shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeButton}></button>
						<div className="d-flex justify-content-center">
							<div className="d-flex flex-column p-4 justify-content-center align-items-center gap-4">
								{props.hours?.map((h, i) => {
									let openDay = new Date();
									let openHour = new Date(h.heureDebut?.date?.replace("-0001-11-30", "1970-01-01")?.split(".")[0]?.replace(/-/g, "/"));
									let closeHour = new Date(h.heureFin?.date?.replace("-0001-11-30", "1970-01-01")?.split(".")[0]?.replace(/-/g, "/"));
									let day = (h.jourDebut + 1) % 7;
									let diffDay = day - openDay.getDay();
									openDay.setDate(openDay.getDate() + diffDay);
									let dayString = openDay.toLocaleString('fr-FR', {weekday: 'long'});
									dayString = dayString[0].toUpperCase() + dayString.slice(1);
									return (
										<div className="d-flex justify-content-between align-items-center gap-4 w-100" key={i}>
											<span>{dayString}</span>
											<div className="bg-light p-2 justify-content-center align-items-center">
												{(h.ouvert == 1 ? (dateFormat(openHour, "HH:MM") + " - " + dateFormat(closeHour, "HH:MM")) : (h.excep == 1 ? t("global.serviceClosedExcep") : t("global.serviceClosed")))}
											</div>
										</div>
									)
								})}
								{props.hours?.length == 0 &&
									<span className="text-center"><i className="bi bi-cone-striped me-3 fs-3"></i><br/>{t("hours.error")}</span>
								}
							</div>
						</div>
                  </div>
               </div>
            </div>
         </div>
	);
}
export default Hours;