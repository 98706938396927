import { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import QuantitySelector from './QuantitySelector.js';

function AddToCart(props) {
	
	const { t } = useTranslation();

	if(props.quantity > 0) {
		return(
			<QuantitySelector quantity={props.quantity} setQuantity={props.setQuantity} />
		);
	}
	else {
		if(props.style == "icon") {
			return(
				<Link onClick={(e) => { props.setQuantity(1) }} className="btn btn-outline-light-dark btn-sm rounded-3 fw-bold px-4 addToCart">
					<i className="bi bi-bag-plus-fill d-flex m-0 h4 text-black"></i>
				</Link>
			);
		}
		else {
			return(
				<Link className="btn btn-danger fw-bold py-3 px-3 rounded-4 shadow" onClick={(e) => { props.setQuantity(1) }}>{t("addToCart.add")}</Link>
			);
		}
	}
}
export default AddToCart;