import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { removeAccents, replaceAll } from '../tools/StringTools.js'
import { showTidio } from '../tools/Tidio.js'
import Icon18 from "./Icon18.js";

function Menu(props) {
	
	const closeButton = useRef();
	const { t } = useTranslation();

	return(
		<div className="offcanvas offcanvas-end my-cart-width p-4" tabIndex="-1" id="navbar-default">
			<div className="d-flex justify-content-end mb-2 d-block">
			   
			   <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeButton}></button>
			</div>
			<div className="d-flex justify-content-center align-items-center mb-5 d-block menu-header">
				<Link to='/' className="navbar-brand m-0 d-lg-block flex-shrink-0" >
					<img src={require('../img/Logo_V2_sans_etiquette.png')} alt="" className="img-fluid logo-menu" />
				</Link>
				<div className="d-flex menu-tagline">
					<h6 className="fw-bold text-dark mb-0 menu-tagline-left">{t("menu.titleOur")} <span className="menu-tagline-highlight bg-danger">{t("menu.titleDrinks")}</span></h6>
					<h6 className="fw-bold text-dark mb-0 menu-tagline-right">{t("menu.titleDelivered")} <span className="menu-tagline-highlight bg-info">{t("menu.titleToYou")}.</span></h6>
				</div>
			</div>
			<div className="d-flex flex-column justify-content-between overflow-auto pe-2 h-100">
				<div className="d-flex flex-column">
					<div className="d-block mb-3 pb-3 border-bottom">
					   <Link to="#collapseCategories" className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-6" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseCategories">
							<div>
								<i className="bi bi-cart3 me-3"></i>
								<span>{t("menu.ourProducts")}</span>
							</div>
							<i className="bi bi-chevron-down"></i>
					   </Link>
					   <div className="collapse mt-2" id="collapseCategories">
						  <div>
							 <ul className="mb-0 list-unstyled px-3">
								{props.categories.map((c, i) => {
								return <li key={i}><Link to={"/" + t("url.delivery") + "/" + replaceAll(removeAccents(c.nom.toLowerCase() + "/" + process.env.REACT_APP_CITY.toLowerCase()), ' ', '-').replace(/[^a-zA-Z0-9-_/\//]/g, '') + "/" + c.idCategorie} className="dropdown-item" onClick={(e) => { closeButton.current.click(); }}>{c.nom}</Link></li>
								})}
							 </ul>
						  </div>
					   </div>
					</div>
					<div className="d-block mb-3 pb-3 border-bottom">
					   <Link to="/concept" className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-6" role="button" onClick={(e) => { closeButton.current.click(); }}>
							<div>
								<i className="bi bi-lightning-charge me-3"></i>
								<span>{t("concept.title")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-block mb-3 pb-3 border-bottom">
					   <Link data-bs-toggle="modal" data-bs-target={"#" + t("hours.url")} className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-6" role="button" onClick={(e) => { closeButton.current.click(); }}>
							<div>
								<i className="bi bi-clock me-3"></i>
								<span>{t("hours.title")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-block mb-3 pb-3 border-bottom">
					   <Link to="/faq" className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-6" role="button" onClick={(e) => { closeButton.current.click(); }}>
							<div>
								<i className="bi bi-question-circle me-3"></i>
								<span>{t("faq.title")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-block mb-3">
					   <Link className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-6" role="button" onClick={(e) => { closeButton.current.click(); showTidio();}}>
							<div>
								<i className="bi bi-chat-left me-3"></i>
								<span>{t("contact.title")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
				</div>
				<div className="d-flex flex-column mt-5">
					<div className="d-block mb-2 pb-2 border-bottom">
					   <Link to={t("cms.cgv.url")} className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-7" role="button" onClick={(e) => { closeButton.current.click();}}>
							<div>
								<i className="bi bi-receipt me-3"></i>
								<span>{t("cms.cgv")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-block mb-2 pb-2 border-bottom">
					   <Link to={t("cms.legal.url")} className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-7" role="button" onClick={(e) => { closeButton.current.click();}}>
							<div>
								<i className="bi bi-info-circle me-3"></i>
								<span>{t("cms.legal")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-block mb-4">
					   <Link to={t("cms.privacy.url")} className="d-flex align-items-center justify-content-between rounded-pill w-100 collapsed menu-item fs-7" role="button" onClick={(e) => { closeButton.current.click();}}>
							<div>
								<i className="bi bi-shield-check me-3"></i>
								<span>{t("cms.privacy")}</span>
							</div>
							<i className="bi bi-chevron-right"></i>
					   </Link>
					</div>
					<div className="d-flex align-items-center">
						<Icon18/>
						<span className="ms-2 mb-0">{t("menu.alcohol")}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Menu;